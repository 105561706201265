@font-face {
  font-family: "Spartan";
  src: url("../../assets/fonts/Spartan/Spartan-Regular.ttf");
  src:
    url("../../assets/fonts/Spartan/Spartan-Regular.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-Black.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-Bold.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-Light.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-Medium.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-SemiBold.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-ExtraLight.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-Thin.ttf") format("truetype"),
    url("../../assets/fonts/Spartan/Spartan-ExtraBold.ttf") format("truetype"),
}
