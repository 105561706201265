@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap");

.section-title {
	@include mq(lg) {
		font-size: 14px;
	}
	@include mq(md) {
		font-size: 12px;
	}
	&.-medium {
		&.-style1 {
			h2 {
				font-size: emCal(16, 24);
			}
		}
	}
	&.-style1 {
		h5 {
			@include typo($fsz: emCal(16, 15), $fw: 600, $up: true);
			letter-spacing: emCal(15, 6);
			margin-bottom: emCal(15, 30);
		}
		h2 {
			@include typo(
				$fsz: emCal(16, 40),
				$lh: emCal(40, 55),
				$fw: 600,
				$cpt: true
			);
			text-transform: uppercase;
			color: rgb(71, 71, 71);
			text-transform: none !important;
		}

		img {
			height: 50px;
      margin-right: 10px;
      margin-bottom: 10px;
		}
		p {
			@include typo(
				$fsz: emCal(16, 15),
				$lh: emCal(15, 24),
				$color: setShadeColor(medium)
			);
			margin-bottom: emCal(15, 25);
		}
	}
}
