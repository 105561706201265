#modal {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80vw;

    overflow-y: auto;
    background-color: #fff;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .modal__wrapper {
      width: 100%;
      max-height: 80vh;
    }
  }
}
