.services__item {
	margin-bottom: remCal(100);
	&.-reverse {
		.services__item__image {
			&__background {
				right: emCal(16, -120);
				left: auto;
				transform: rotateY(180deg);
			}
			&__detail {
				.image__item {
					&:first-child {
						// right: auto;
						// left: 0;
						right: 20px;
						left: 0px;
					}
					&:last-child {
						// left: auto;
						// right: 0;
						// left: 60px;
						// right: 0px;

						// left: 140px;
						// right: -100px;

						left: 220px;
						right: -170px;
					}
				}
			}
		}
		.services__item__content {
			padding-left: 0;
			padding-right: emCal(16, 60);
			text-align: right;
		}
	}
	&__image {
		position: relative;
		height: 410px;
		width: 70%;

		// position: relative;
		// height: 310px;
		// width: 70%;

		@include mq(md) {
			margin-bottom: 0px;
		}
		&__background {
			position: absolute;
			top: 0;
			left: emCal(16, -120);
			img {
				@include imgCenter($type: contain);
			}
			@include mq(lg) {
				max-width: 100%;
				left: 0;
			}
		}
		&__detail {
			position: relative;
			width: 100%;
			height: 100%;
			.image__item {
				position: absolute;
				img {
					@include imgCenter($type: contain);
				}
				&:first-child {
					// top: 0;
					// right: 0;

					top: 70px;
					right: 30px;
					@include mq(md) {
						max-width: 80%;
					}
				}
				&:last-child {
					@include mq(md) {
						max-width: 80%;
						left: 160px !important;
						right: -90px !important;
					}
					// bottom: 0;
					// left: 0;

					// bottom: 160px;
					// left: 110px;

					// bottom: 120px;
					// left: 80px;

					// bottom: 170px;
					// left: 130px;
					// right: -40px;

					bottom: 130px;
					left: 160px;
					right: -90px;
				}
			}
		}
	}
	&__content {
		padding-left: emCal(16, 60);
		@include mq(md) {
			padding-left: 0;
			padding-right: 0 !important;
		}
		.services__item {
			&__order {
				margin-bottom: emCal(16, 30);
				h3 {
					@include typo($fsz: emCal(16, 30), $color: setColor(red), $fw: 700);
				}
			}
			&__title {
				@include typo($fsz: emCal(16, 42), $lh: emCal(42, 60), $fw: 600);
				margin-bottom: emCal(42, 20);
			}
			&__description {
				@include typo(
					$fsz: emCal(16, 15),
					$lh: emCal(15, 27),
					$color: setShadeColor(medium)
				);
				margin-bottom: emCal(15, 30);
			}
		}
		ul {
			list-style: none;
			margin-bottom: emCal(16, 30);
			li {
				margin-bottom: emCal(16, 15);
				@include typo($fsz: emCal(16, 15), $color: setShadeColor(medium));
				i {
					color: setColor(red);
				}
			}
		}
	}
}
