.video-frame {
  position: relative;
  &__poster {
    img {
      @include imgCenter;
    }
  }
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: setColor(red);
  }
}
