$avaiableBtnColors: (
  red: setColor(red),
  light-red: #f26460,
  dark: setShadeColor(dark),
  light: #f5f5f5,
  white: #fff,
  transparent: transparent,
);

.btn {
  transition: $transition;
  font-size: emCal(16, 13);
  line-height: emCal(16, 26);
  text-align: center;
  text-decoration: none;
  width: auto;
  height: auto;
  display: inline-block;
  padding: emCal(13, 15) emCal(13, 30);
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  letter-spacing: emCal(13, 1.3);
  @include prefix(appearance, none);
  &.-disable {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.-round {
    border-radius: 999px;
  }
  &.-underline {
    border: 0;
    padding: 0;
    padding-bottom: emCal(16, 10);
    position: relative;
    overflow: hidden;
    &:after,
    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: setColor(red);
      position: absolute;
      left: 0;
      bottom: 0;
      transition: $transition;
    }
    &:after {
      left: -100%;
      background-color: setShadeColor(dark);
    }
    &:hover {
      &:after {
        left: 0;
      }
    }
  }
  &:focus {
    outline: 0;
  }
  @each $name, $color in $avaiableBtnColors {
    &.-#{$name} {
      background-color: $color;
      color: #fff;
      &:hover {
        background-color: darken($color, 10%);
      }
      @if $name ==white {
        border: 1px solid setShadeColor(light);
        color: setShadeColor(dark);
      } @else if $name == transparent {
        color: setShadeColor(dark);
      } @else if $name == light {
        color: setShadeColor(dark);
      }
    }
  }
}
