.error-404 {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: {
    image: url("/assets/images/404/bg.png");
    size: cover;
    position: center;
  }
  &__content {
    h2 {
      @include typo($fsz: emCal(16, 200), $color: setColor(red), $fw: 700);
      margin-bottom: emCal(200, 40);
      @include mq(md) {
        font-size: emCal(14, 80);
        margin-bottom: emCal(80, 30);
      }
    }
    h5 {
      @include typo($fsz: emCal(16, 36));
      margin-bottom: emCal(36, 40);
      @include mq(md) {
        font-size: emCal(14, 20);
      }
    }
    p {
      @include typo($fsz: emCal(16, 18), $lh: emCal(16, 27));
      margin-bottom: emCal(18, 40);
    }
    .form-wrapper {
      display: flex;
      align-items: center;
      form {
        position: relative;
        margin-right: emCal(16, 30);
        max-width: emCal(16, 400);
        flex-grow: 1;
        input {
          @include input;
        }
        button {
          position: absolute;
          top: 50%;
          right: emCal(14, 10);
          padding: 0;
          font-size: emCal(16, 20);
          transform: translateY(-50%);
          z-index: 2;
        }
      }
      .btn {
        font-weight: 700;
      }
    }
  }
}
