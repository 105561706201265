.header {
  min-height: 0.1px;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90;
    animation: example 1s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
    box-shadow: 0px 2px 10px 0 rgba(223, 223, 224, 0.5);
    .menu {
      &.-style-5 {
        background-color: #fff;
        box-shadow: 0px 2px 10px 0 rgba(223, 223, 224, 0.5);
      }
    }
  }
}

@keyframes example {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(100);
  }
}
