.customed-select {
  @include prefix(appearance, none);
  padding-right: emCal(16, 35);
  font-size: 1em;
  color: setShadeColor(dark);
  cursor: pointer;
  border: 1px solid setShadeColor(light);
  background: {
    image: url("/assets/images/common/Select/ic-down.png");
    position: 100% center;
    repeat: no-repeat;
    size: 12px;
    color: #fff;
  }
  option {
    @include typo($fsz: 1em);
    outline: 0;
    border: 0;
    &[disable] {
      color: setShadeColor(medium);
    }
  }
  &.-borderless {
    border: 0;
    background-color: transparent;
  }
  &.-white {
    color: #fff;
    background-image: url("/assets/images/common/Select/ic-down-white.png");
    option {
      @include typo($fsz: 1em);
    }
  }
}
