@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container-full-half {
  max-width: 1700px;
  @include make-container($gutter: 70px);
  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}