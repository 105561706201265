$colors: (
  red: #10ABDE,
  darkRed: #006283,
  star: #ecba0b,
  blue: #1a7be9,
);

$shades: (
  dark: #111,
  medium: #888,
  light: #e1e1e1,
);

$breakpoint: (
  sm: " 576px",
  md: "768px",
  lg: "992px",
  xl: "1170px",
  xxl: "1400px",
  xxxl: "1770px",
);

$primary-color: #10ABDE;
$white-color: #ffffff;
$black-color: rgb(41, 41, 41);
$custom-underline: #f5f5f5c4;
$rem-baseline: 16;
$transition: 0.2s ease-in-out;
$prefixes: (webkit, moz, ms, o);
$cambay: "Cambay", sans-serif;
