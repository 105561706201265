@import "../abstracts/variables";

$main-color: #00a8dc;
$optional-color: #777777;
$black-color: #000000;
$white-color: #ffffff;
$transition: 0.5s;
/* Admin top navbar */
.admin-top-navbar {
	background: #00a8dc;
	-webkit-box-shadow: 0px 0 5px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 0 5px rgba(0, 0, 0, 0.08);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	padding: 5px 35px;
}

.admin-top-navbar .navbar-nav {
	display: block;
}

.admin-top-navbar .navbar-nav .nav-item {
	display: inline-block;
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.admin-top-navbar .navbar-nav .nav-item a {
	color: #000000;
	/* text-transform: capitalize; */
	position: relative;
	font-weight: 600;
	font-size: 15px;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.admin-top-navbar .navbar-nav .nav-item a i {
	font-size: 18px;
	position: relative;
	top: 2px;
	display: inline-block;
	margin-left: -2px;
	margin-right: -2px;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu {
	-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
	background: #ffffff;
	position: absolute;
	top: 80px;
	left: auto;
	right: 0;
	border: none;
	display: block;
	width: 200px;
	z-index: 99;
	opacity: 0;
	border-radius: 5px;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li {
	position: relative;
	padding: 0;
	display: block;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li a {
	display: block;
	font-size: 14px;
	color: #000000;
	border-bottom: 1px dashed #eee;
	padding: 15px 15px;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li a::before {
	display: none;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li a.active {
	color: #184d5e;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
	position: absolute;
	left: 220px;
	top: 0;
	opacity: 0 !important;
	visibility: hidden !important;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
	color: #000000;
}

.admin-top-navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:hover,
.admin-top-navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	a:focus,
.admin-top-navbar
	.navbar-nav
	.nav-item
	.dropdown-menu
	li
	.dropdown-menu
	li
	a.active {
	color: #184d5e;
}

.admin-top-navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
	opacity: 1 !important;
	visibility: visible !important;
	top: -20px !important;
}

.admin-top-navbar .navbar-nav .nav-item:hover ul {
	opacity: 1;
	visibility: visible;
	top: 100%;
}

.admin-top-navbar .navbar-nav .nav-item.profile {
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
}

.admin-top-navbar .navbar-nav .nav-item.profile img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.admin-top-navbar .navbar-nav .nav-item.add-products {
	padding-top: 0;
	padding-bottom: 0;
}

.admin-top-navbar .navbar-nav .nav-item.add-products a {
	background-color: white;
	color: black;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
}

.admin-top-navbar .navbar-nav .nav-item.add-products a:hover {
	background-color: #000000;
	color: white;
}

.admin-top-navbar .navbar-nav .nav-item.notification .count-info {
	position: relative;
}

.admin-top-navbar .navbar-nav .nav-item.notification .count-info .circle {
	position: absolute;
	background: #184d5e;
	color: #fff;
	height: 10px;
	width: 10px;
	text-align: center;
	border-radius: 50%;
	line-height: 22px;
	font-size: 10px;
	top: -2px;
	right: -4px;
	z-index: 1;
}

.admin-top-navbar
	.navbar-nav
	.nav-item.notification
	.count-info
	.circle
	.ripple {
	background-color: #184d5e;
	position: absolute;
	width: 22px;
	height: 22px;
	z-index: -1;
	right: -6px;
	top: -6px;
	opacity: 0;
	margin: 0;
	border-radius: 100px;
	-webkit-animation: ripple 2.8s infinite;
	animation: ripple 2.8s infinite;
}

.admin-top-navbar
	.navbar-nav
	.nav-item.notification
	.count-info
	.circle
	.ripple:nth-child(2) {
	animation-delay: 0.6s;
	-webkit-animation-delay: 0.6s;
}

.admin-top-navbar
	.navbar-nav
	.nav-item.notification
	.count-info
	.circle
	.ripple:nth-child(3) {
	animation-delay: 0.9s;
	-webkit-animation-delay: 0.9s;
}

@-webkit-keyframes ripple {
	0% {
		opacity: 1;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes ripple {
	0% {
		opacity: 1;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.admin-top-navbar .navbar-nav .nav-item.notification .dropdown-menu {
	width: 250px;
}

.admin-top-navbar .navbar-nav .nav-item.notification .dropdown-menu li a {
	padding: 15px 20px;
}

.admin-top-navbar .navbar-nav .nav-item.notification a {
	font-size: 15px;
}

.admin-top-navbar .navbar-nav .nav-item.notification a .status {
	font-weight: 600;
	margin-right: 5px;
}

.admin-top-navbar .navbar-nav .nav-item.notification a .time {
	font-size: 12px;
	color: #777777;
}

.admin-top-navbar .navbar-nav .nav-item.notification a p {
	margin: 0;
	font-size: 13px;
}

.admin-top-navbar .navbar-nav .nav-item.notification .feeds {
	color: #184d5e;
	font-weight: 600;
	text-align: center;
	display: block;
}

.admin-top-navbar .burger-menu {
	padding-right: 15px;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	display: none;
}

.admin-top-navbar .burger-menu span {
	height: 1px;
	width: 25px;
	background: #303030;
	display: block;
	margin: 6px 0;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
}

/* Admin sidebar */
.admin-sidebar {
	background: #fff;
	border-top: 1px solid #f6f6f7;
	position: fixed;
	height: 100%;
	width: 220px;
	-webkit-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.05);
	box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.05);
	z-index: 1030;
	left: 0;
	top: 80px;
	padding-top: 20px;
}

.admin-sidebar ul {
	max-height: calc(100vh - 8rem);
	overflow-y: auto;
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.admin-sidebar ul li {
	height: 90px;
}

.admin-sidebar ul li a {
	padding: 15px 30px;
  font-size: 14px;
  color: #000000;
	font-weight: 600;
	border-radius: 30px 0px 0px 30px;
}

.admin-sidebar ul li a:hover {
color: $main-color;
}


.admin-sidebar ul li a i {
	margin-right: 8px;
}

.admin-sidebar ul li a.active {
	color: #184d5e;
	background: #eeeef8;
}

/* Admin main content */
.admin-main-content {
	background-color: #eeeef8;
	width: 100%;
	margin-top: 80px;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	padding: 30px 30px 0;
	position: relative;
	min-height: calc(100vh - 62px);
	padding-left: 250px;
	overflow: hidden;
}

.admin-main-content .page-header {
	border-bottom: 1px dashed #d9d9d9;
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.admin-main-content .page-header h2 {
	font-size: 20px;
	margin: 0;
}

/* Admin products */
.admin-products .single-products-box {
	background-color: #ffffff;
	padding: 20px;
}

.admin-products .single-products-box .products-image .main-image {
	width: 100%;
}

.admin-products .single-products-box .products-image .hover-image {
	width: 100%;
}

.invisible-btn {
	outline: none;
	cursor: pointer;
	border: none;
	background-color: transparent;
	margin: none;
	color: white;
}

.admin-products .single-products-box .products-image .products-button ul li {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}

.admin-products
	.single-products-box
	.products-image
	.products-button
	ul
	li
	a
	i {
	background-color: #184d5e;
	color: #ffffff;
	height: 30px;
	width: 30px;
	line-height: 30px;
	text-align: center;
	border-radius: 50%;
	font-size: 20px;
}

.admin-products .single-products-box .products-image .products-button {
	ul {
		li {
			transform: translateX(0);
			opacity: 1;
			visibility: visible;
			a {
				i {
					background-color: $main-color;
					color: $white-color;
					height: 30px;
					width: 30px;
					line-height: 30px;
					text-align: center;
					border-radius: 50%;
					font-size: 20px;
				}
			}
		}
	}
}

.single-products-box {
	position: relative;
	margin-bottom: 30px;

	.products-image {
		text-align: center;
		position: relative;
		overflow: hidden;

		a {
			position: relative;
			display: block;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				transition: 0.8s;
			}
			.hover-image {
				position: absolute;
				left: 0;
				right: 0;
				opacity: 0;
				visibility: hidden;
				transform: scale(1.07);
			}
		}
		.products-button {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 1;

			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					margin-bottom: 8px;
					opacity: 0;
					visibility: hidden;
					transform: translateX(30px);
					transition: all 0.3s ease-in-out 0s;

					a {
						display: inline-block;
						font-size: 25px;
						line-height: 1;
						color: $black-color;
						position: relative;

						&:hover {
							color: $main-color;
						}
						.tooltip-label {
							right: 110%;
							top: 45%;
							transform: translateX(0) translateY(-45%);
							position: absolute;
							padding: 0 10px;
							line-height: 28px;
							font-size: 15px;
							letter-spacing: 0.3px;
							visibility: hidden;
							white-space: nowrap;
							opacity: 0;
							color: transparent;
							background-color: transparent;
							transition: all 0.2s ease-in-out;
							background-color: $main-color;
							color: $white-color;

							&::before {
								content: "\e9ff";
								color: $main-color;
								top: 50%;
								position: absolute;
								transform: translateY(-50%);
								transition: all 0.2s ease-in-out;
								right: -13px;
								font: {
									family: "boxicons" !important;
									weight: normal;
									style: normal;
									size: 20px;
								}
							}
						}
						&:hover {
							.tooltip-label {
								visibility: visible;
								opacity: 1;
								right: 135%;
							}
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
					&:nth-child(1) {
						transition-delay: 0.1s;
					}
					&:nth-child(2) {
						transition-delay: 0.2s;
					}
					&:nth-child(3) {
						transition-delay: 0.3s;
					}
				}
			}
		}
		.sale-tag {
			position: absolute;
			left: 20px;
			top: 60px;
			background-color: bisque;
			// background-color: $alternative-color;
			color: $white-color;
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			border-radius: 50%;
			z-index: 2;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
		.new-tag {
			background-color: bisque;
			position: absolute;
			left: 20px;
			top: 20px;
			// background-color: $alternative-color-2;
			color: $white-color;
			text-align: center;
			z-index: 2;
			padding: 1px 7px;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
	}
	.products-content {
		margin-top: 20px;
		position: relative;

		h3 {
			margin-bottom: 0;
			font: {
				size: 20px;
				weight: 600;
			}
			a {
				display: inline-block;
				color: $black-color;

				&:hover {
					color: $main-color;
				}
			}
		}
		.price {
			margin-top: 8px;
			transition: $transition;
			font: {
				// size: $font-size;
				weight: 600;
			}
			.old-price {
				text-decoration: line-through;
				color: #999999;
				font-weight: 500;
				margin-right: 10px;
			}
		}
		.star-rating {
			position: absolute;
			right: 0;
			bottom: 0;
			color: #f49f0b;

			i {
				display: inline-block;
			}
		}
		.add-to-cart {
			position: absolute;
			left: 0;
			bottom: 0;
			text-transform: uppercase;
			// transition: $transition;
			display: inline-block;
			// color: $optional-color;
			opacity: 0;
			visibility: hidden;
			transform: translateX(20px);
			cursor: pointer;
			font: {
				size: 14px;
				weight: 600;
			}
			&:hover {
				color: $main-color;
			}
		}
		.products-discount {
			position: absolute;
			top: -6.1px;
			right: 10px;
			display: inline-block;

			span {
				position: relative;
				display: block;
				text-align: center;
				line-height: 1;
				padding: 12px 8px 10px;
				border-top-right-radius: 8px;
				width: 90px;
				// background-color: $alternative-color-2;
				color: $white-color;
				text-transform: uppercase;
				font: {
					weight: 600;
					size: 14px;
				}
				&:before {
					height: 6px;
					width: 6px;
					left: -6px;
					position: absolute;
					content: "";
					top: 0;
					// background-color: $alternative-color-2;
				}
				&:after {
					position: absolute;
					content: "";
					height: 6px;
					width: 8px;
					left: -8px;
					top: 0;
					border-radius: 8px 8px 0 0;
					// background-color: $alternative-color-2;
				}
			}
		}
	}
	.products-discount {
		position: absolute;
		top: -6.1px;
		left: 10px;
		display: inline-block;

		span {
			position: relative;
			display: block;
			text-align: center;
			line-height: 1;
			padding: 12px 8px 10px;
			border-top-left-radius: 8px;
			width: 90px;
			// background-color: $alternative-color-2;
			// color: $white-color;
			text-transform: uppercase;
			font: {
				weight: 600;
				size: 14px;
			}
			&:before {
				height: 6px;
				width: 6px;
				right: -6px;
				position: absolute;
				content: "";
				top: 0;
				// background-color: $alternative-color-2;
			}
			&:after {
				position: absolute;
				content: "";
				height: 6px;
				width: 8px;
				right: -8px;
				top: 0;
				border-radius: 8px 8px 0 0;
				// background-color: $alternative-color-2;
			}
		}
	}
	&:hover {
		.products-content {
			.price {
				opacity: 0;
				visibility: hidden;
			}
			.add-to-cart {
				transform: translateX(0);
				opacity: 1;
				visibility: visible;
			}
		}
		.products-image {
			a {
				.main-image {
					opacity: 0;
					visibility: hidden;
					transform: scale(1.07);
				}
				.hover-image {
					opacity: 1;
					visibility: visible;
					transform: scale(1);
				}
			}
			.products-button {
				ul {
					li {
						transform: translateX(0);
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}

.admin-products .single-products-box .products-content .price {
	opacity: 1 !important;
	visibility: visible !important;
}

.admin-pagi {
	text-align: center;
	margin-bottom: 30px;
	margin-top: 0;
}

.admin-pagi::before {
	display: none;
}

.voucher-image {
	height: 400px;
}

.my-row {
	display: flex;
}

/* Solid border */
.solid-border {
	border-top: 1px solid #bbb;
	margin-bottom: 4px;
}

.admin-pagi .page-numbers {
	width: 35px;
	height: 35px;
	line-height: 35px;
	border-radius: 50%;
	margin: 0 3px;
	font-size: 15px;
}

.admin-pagi .page-numbers.current {
	background-color: #184d5e;
	color: #ffffff;
}

.admin-pagi .page-numbers.prev,
.admin-pagi .page-numbers.next {
	font-size: 25px;
	top: 6px;
	margin-left: 3px;
	margin-right: 3px;
	padding: 0;
	line-height: 38px;
}

/* Admin product modal */
.admin-product-modal {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 99999;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.admin-product-modal .modal-innter-content {
	width: 700px;
	background-color: #ffffff;
	height: 100%;
	margin-left: auto;
	overflow-y: auto;
	margin-right: -100%;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.admin-product-modal .modal-innter-content .modal-body {
	padding: 40px;
}

.admin-product-modal .modal-innter-content .modal-body h3 {
	margin-bottom: 25px;
	padding-bottom: 5px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 20px;
	font-weight: 600;
}

.admin-product-modal .modal-innter-content .modal-body h3::before {
	width: 50px;
	height: 1px;
	bottom: -1px;
	content: "";
	position: absolute;
	left: 0;
	background-color: #184d5e;
}

.admin-product-modal .modal-innter-content .modal-body form label {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 10px;
}

.admin-product-modal .modal-innter-content .modal-body form .title {
	font-size: 18px;
}

.admin-product-modal .modal-innter-content .modal-body form .upload-img {
	position: relative;
	border: 2px dashed #eee;
	border-radius: 5px;
	background: #f7f9fb;
	-webkit-transition: all 0.3s linear;
	transition: all 0.3s linear;
	text-align: center;
	cursor: pointer;
}

.admin-product-modal .modal-innter-content .modal-body form .upload-img span {
	display: block;
	font-weight: 600;
	padding-top: 50px;
	padding-bottom: 50px;
	font-size: 14px;
}

.admin-product-modal .modal-innter-content .modal-body form .upload-img span i {
	display: block;
	font-size: 50px;
	margin-bottom: 15px;
	color: #5a5757;
}

.admin-product-modal .modal-innter-content .modal-body form .upload-img input {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}

.admin-product-modal .modal-innter-content .modal-body form .uploaded-img {
	width: 100px;
	margin-top: 15px;
}

.admin-product-modal .modal-innter-content .modal-body form .btn {
	text-transform: capitalize;
	background-color: $main-color;
}

.admin-product-modal .modal-innter-content .modal-body form .modal-btn {
	overflow: hidden;
	margin-top: 30px;
}

.admin-product-modal .modal-innter-content .view-product-content .product-img {
	position: relative;
}

.admin-product-modal
	.modal-innter-content
	.view-product-content
	.product-img
	.sale-tag {
	position: absolute;
	left: 20px;
	top: 20px;
	background-color: red;
	color: #ffffff;
	width: 55px;
	height: 55px;
	line-height: 55px;
	text-align: center;
	border-radius: 50%;
	z-index: 2;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
}

.admin-product-modal
	.modal-innter-content
	.view-product-content
	.product-img
	.new-tag {
	position: absolute;
	left: 20px;
	top: 20px;
	background-color: green;
	color: #ffffff;
	text-align: center;
	z-index: 2;
	padding: 1px 7px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
}

.admin-product-modal
	.modal-innter-content
	.view-product-content
	.product-img
	.discount {
	position: absolute;
	left: 20px;
	top: 20px;
	background-color: red;
	color: #ffffff;
	text-align: center;
	z-index: 2;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	padding: 6px 15px;
}

.admin-product-modal
	.modal-innter-content
	.view-product-content
	.products-details-desc
	h3 {
	border: none;
	margin: 0 0 10px;
}

.admin-product-modal
	.modal-innter-content
	.view-product-content
	.products-details-desc
	h3::before {
	display: none;
}

.admin-product-modal.active {
	opacity: 1;
	visibility: visible;
}

.admin-product-modal.active .modal-innter-content {
	margin-right: 0;
}

.admin-product-modal .close {
	color: #000000;
	font-size: 28px;
	outline: 0 !important;
	line-height: initial;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	position: relative;
	right: 40px;
	top: 37px;
	z-index: 1;
}

.admin-product-modal .close:not(:disabled):not(.disabled):hover,
.admin-product-modal .close:not(:disabled):not(.disabled):focus {
	color: red;
	text-decoration: none;
	opacity: 1;
}

.admin-product-modal.view-product .modal-innter-content {
	width: 1000px;
}

/* Admin table */
.admin-table {
	background-color: #ffffff;
	margin-bottom: 30px;
}

.admin-table .title {
	font-size: 18px;
	padding: 15px 15px 0;
}

.admin-table table td,
.admin-table table th {
	border-top: 1px solid #f5f5f5;
	vertical-align: middle;
	padding: 15px 20px;
}

.admin-table table td {
	font-size: 14px;
}

.admin-table table td .badge {
	padding: 5px 10px;
}

.admin-table table .customer img,
.admin-table table .product img {
	border-radius: 50%;
	height: 30px;
	width: 30px;
	margin-right: 8px;
}

.admin-table.height-555 {
	height: 555px;
	overflow-y: auto;
}

.badge_warning {
	background-color: rgba(255, 190, 11, 0.2);
	color: #ffbe0b;
}

.badge_success {
	color: #00c9a7;
	background: rgba(0, 201, 167, 0.1);
}

.badge_danger {
	background-color: rgba(255, 92, 117, 0.2);
	color: #ff5c75;
}

.admin-orders-table table tbody tr {
	cursor: pointer;
}

/* Stats card */
.stats-card {
	background-color: #ffffff;
	margin-bottom: 30px;
	padding: 25px;
	border-radius: 4px;
	overflow: hidden;
}

.stats-card h3 {
	font-size: 18px;
	margin: 0;
}

.stats-card p {
	color: #686c71;
	margin: 0;
	font-size: 13px;
}

.stats-card .avatar {
	font-size: 30px;
	text-align: center;
	background: #f1f2f3;
	color: #fff;
	white-space: nowrap;
	position: relative;
	overflow: hidden;
	vertical-align: middle;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50%;
	display: inline-block;
}

.stats-card .growth {
	font-size: 17px;
	margin: 20px 0 0;
}

.stats-card .growth .badge {
	font-size: 12px;
	float: right;
	font-weight: 500;
	padding: 5px 8px;
}

.avatar-pink {
	color: #184d5e !important;
	background: rgba(245, 63, 133, 0.1) !important;
}

.avatar-blue {
	color: #2962ff !important;
	background: rgba(63, 135, 245, 0.1) !important;
}

.avatar-cyan {
	color: #00c9a7 !important;
	background: rgba(0, 201, 167, 0.1) !important;
}

.avatar-gold {
	color: #ffc107 !important;
	background: rgba(255, 193, 7, 0.1) !important;
}

.avatar-purple {
	color: #886cff !important;
	background: rgba(136, 108, 255, 0.1) !important;
}

.chart-box {
	background-color: #ffffff;
	border-radius: 4px;
}

/* Chart box */
.chart-box {
	background-color: #ffffff;
	padding: 25px;
	border-radius: 4px;
	margin-bottom: 30px;
}

.chart-box .header h3 {
	margin: 0;
	font-size: 18px;
}

.admin-icon {
	height: 60px;
}

/* Admin signin */
.admin-signin {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100vh;
	background-image: url("../../assets/images/backgrounds/bg1.jpg");
	background-size: cover;
	background-position: center center;
}

.admin-signin .login-content {
	background-color: #fff;
	border: 1px solid #eee;
	padding: 40px 30px;
	max-width: 600px;
	margin: auto;
	-webkit-box-shadow: 0 0 30px #eee;
	box-shadow: 0 0 30px #eee;
	border-radius: 10px;
}

.admin-signin .login-content h2 {
	text-align: center;
}

.loading {
	text-align: center;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.loading h1 {
	margin: 0;
	font-size: 20px;
}

/* Responsive CSS */
@media only screen and (max-width: 767px) {
	.admin-signin {
		height: 100%;
	}
	.admin-top-navbar {
		padding: 10px 20px;
	}
	.admin-top-navbar .navbar-brand {
		display: none;
	}
	.admin-top-navbar .navbar-nav .nav-item {
		padding-left: 10px;
		padding-right: 10px;
	}
	.admin-top-navbar .navbar-nav .nav-item.add-products a {
		padding: 10px 15px;
		font-size: 12px;
	}
	.admin-sidebar {
		top: 70px;
		left: -100%;
		-webkit-transition: 0.5s;
		transition: 0.5s;
	}
	.admin-sidebar.active {
		left: 0;
	}
	.admin-main-content {
		margin-top: 70px;
		padding: 30px 15px 0;
		padding-left: 15px;
	}
	.stats-card h3 {
		font-size: 16px;
	}
	.stats-card .growth {
		font-size: 15px;
	}
	.admin-table table td,
	.admin-table table th {
		padding: 10px 15px;
		font-size: 13px;
	}
	.burger-menu {
		display: block !important;
	}
	.admin-product-modal .modal-innter-content {
		width: 100%;
	}
	.admin-product-modal .modal-innter-content .modal-body {
		padding: 30px;
	}
	.admin-product-modal .close {
		right: 30px;
		top: 28px;
	}
	.admin-product-modal.view-product .modal-innter-content {
		width: 100%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-products-box .products-content h3 {
		font-size: 15px;
	}
	.single-products-box .products-content .price {
		font-size: 13px;
	}
	.admin-sidebar {
		top: 70px;
		left: -100%;
		-webkit-transition: 0.5s;
		transition: 0.5s;
	}
	.admin-sidebar.active {
		left: 0;
	}
	.admin-main-content {
		padding-left: 30px;
	}
	.admin-top-navbar .burger-menu {
		padding-right: 15px;
		padding-left: 15px;
		display: block;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.stats-card {
		padding: 18px;
		text-align: center;
	}
	.stats-card h3 {
		font-size: 15px;
	}
	.stats-card .media {
		display: block;
	}
	.stats-card .avatar {
		font-size: 20px;
		width: 35px;
		height: 35px;
		line-height: 35px;
		margin-top: 10px;
	}
	.stats-card .growth .badge {
		float: inherit;
		margin-top: 10px;
		display: block;
		padding: 8px 8px;
	}
	.single-products-box .products-content h3 {
		font-size: 15px;
	}
	.single-products-box .products-content .price {
		font-size: 13px;
	}
}
/*# sourceMappingURL=dashboard.css.map */
