.post {
  margin-bottom: remCal(100);
  &__cover {
    max-height: emCal(16, 600);
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1) rotate(1deg);
      }
    }
    img {
      @include imgCenter;
      transition: 5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }
  &__body {
    .post__sidebar {
      padding-top: emCal(16, 100);
      @include mq(md) {
        margin-bottom: emCal(14, 70);
      }
    }
    .post__content {
      background-color: #fff;
      margin-top: emCal(16, -50);
      padding: emCal(16, 50) emCal(16, 70) 0;
      @include mq(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.article-video {
  iframe {
      width: 100%;
      height: 400px;
      border: none !important;
  }
}

.wp-block-gallery {
  &.columns-3 {
    justify-content: center;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: {
      right: -10px;
      left: -10px;
      bottom: 30px;
      top: 30px;
    }
    li {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      padding: {
        right: 10px;
        left: 10px;
      }
      figure {
        margin-bottom: 0;

        img {
          width: 100%;
        }
      }
    }

    .li-for2 {
      flex: 0 0 50%;
      max-width: 50%;
      padding: {
        right: 10px;
        left: 10px;
      }
      figure {
        margin-bottom: 0;

        img {
          width: 100%;
        }
      }
    }

    .li-for1 {
      flex: 0 0 50%;
      max-width: 50%;
      padding: {
        right: 10px;
        left: 10px;
      }
      figure {
        margin-bottom: 0;

        img {
          width: 100%;
        }
      }
    }
  }
}