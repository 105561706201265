
#mercadoForm {

  width: 100%;
}
.mercadopago-button {
  padding-top: 0;
    padding-bottom: 0;
    height: 2.4em;
    width: 100% !important;
    background-color: #10ABDE;
    color: #fff;
    font-size: 1.0em !important;
    line-height: 1.0em !important;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    letter-spacing: 0.1em !important;
    border: 0 !important;
    font-family: "Poppins" !important;
}

.checkout {
  margin-bottom: remCal(100);
  .checkout-title {
    @include typo($fsz: emCal(16, 20), $fw: 600);
    margin-bottom: emCal(20, 30);
  }
  .checkbox-label {
    display: block;
    @include typo($fsz: emCal(16, 14), $lh: emCal(14, 24));
    input {
      margin-right: emCal(16, 15);
    }
  }
  &__form {
    @include mq(lg) {
      margin-bottom: emCal(16, 70);
    }
    .input-validator {
      margin-bottom: emCal(16, 30);
      label {
        @include typo($fsz: emCal(16, 14));
        span {
          color: setColor(red);
        }
        input {
          margin-top: emCal(16, 15);
        }
      }
      input {
        @include input($height: emCal(14, 45));
      }
    }

    &__contact {
      margin-bottom: emCal(16, 45);
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: emCal(16, 30);

        .checkout-title {
          margin-bottom: 0;
          @include mq(md) {
            margin-bottom: emCal(16, 15);
          }
        }
        p {
          @include typo($fsz: emCal(16, 15));
          a {
            @include link($color: setColor(red));
            margin-left: emCal(15, 10);
          }
        }
      }
    }
  }
  &__total {
    padding: emCal(16, 30);
    background-color: #f7f5f4;
    .btn {
      padding-top: 0;
      padding-bottom: 0;
      height: emCal(14, 45);
      line-height: enCal(14, 45);
      width: 100%;
    }
    &__coupon {
      margin-bottom: emCal(16, 40);
      h5 {
        @include typo($fsz: emCal(16, 15), $fw: 600);
        margin-bottom: emCal(16, 25);
      }
      .input-validator {
        margin-bottom: emCal(14, 15);
        input {
          @include input($height: emCal(14, 45));
        }
      }
    }
    &__price {
      margin-bottom: emCal(16, 30);
      h5 {
        @include typo($fsz: emCal(16, 15), $fw: 600);
        margin-bottom: emCal(16, 25);
      }
      table {
        width: 100%;
        margin-bottom: emCal(16, 30);
        tr {
          &:last-child {
            td {
              padding-bottom: 0;
            }
          }
          td {
            @include typo($fsz: emCal(16, 14), $lh: emCal(14, 24));
            padding-bottom: emCal(14, 10);
            span {
              font-weight: 700;
            }
            &:last-child {
              text-align: right;
              font-weight: 700;
            }
          }
        }
      }
      &__total-count {
        padding: emCal(16, 30) 0;
        border: 1px solid setShadeColor(light);
        border-width: 1px 0;
        margin-bottom: emCal(16, 30);
        table {
          margin-bottom: 0;
          tr {
            td {
              font-weight: 700;
            }
          }
        }
      }
      &__payment {
        .checkbox-label {
          &:first-child {
            margin-bottom: emCal(16, 15);
          }
        }
      }
    }
  }
}
