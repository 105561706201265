.review {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: emCal(16, 25);
    &__avatar {
      margin-right: emCal(16, 20);
      img {
        @include imgCenter($height: emCal(16, 50), $width: emCal(16, 50));
        border-radius: 999px;
      }
    }
    &__info {
      h5 {
        @include typo($fsz: emCal(16, 15), $fw: 600);
        margin-bottom: emCal(15, 10);
      }
      p {
        @include typo($fsz: emCal(16, 13), $color: setShadeColor(medium));
      }
    }
    &__rate {
      margin-left: auto;
    }
  }
  &__content {
    @include typo($fsz: emCal(16, 15), $lh: emCal(15, 27));
    margin-bottom: emCal(16, 20);
  }
  &__report {
    @include link($fsz: emCal(16, 13), $color: setShadeColor(medium));
    text-decoration: underline;
  }
}
