.paginator {
  list-style: none;
  li {
    display: inline-block;
    margin: 0 emCal(16, 5);
    @include spaceTrim;
    &.active {
      button {
        @extend .btn, .-dark;
        height: emCal(14, 50);
        width: emCal(14, 50);
        padding: 0;
      }
    }
    button {
      @extend .btn, .-white, .-round;
      height: emCal(14, 50);
      width: emCal(14, 50);
      padding: 0;
    }
  }
}
